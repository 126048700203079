@import url('https://fonts.googleapis.com/css2?family=Kumbh+Sans:wght@200;300;400;500;700&display=swap');

:root{
  /*Colors*/
  --clrFuchsia:#552655;
  --clrOrange:#ff7e1b;
  --clrWarning:#ff797936;
  --clrGreen:#18977cda;
  --clrDarkGreen:#339c79;
  --clrLightOrange:#FFEEE2;
  --clrVeryDarkBlue:#1D2026;
  --clrDarkBlue:#2e4064;
  --clrMediumBlue:#486194;
  --clrBlueGrey:#69707D;
  --clrLightGrey:rgb(182, 188, 200);
  --clrVeryLightGrey:#F6F8FD;
  --clrSeperator:#E4E9F2;

  /*Typography*/
  --ffMain:'Kumbh Sans', sans-serif;  
  --widthDesktop:1440px;
  --widthDesktopMedium:960px;
  --widthTablet:730px;
  --widthMobile:640px;

  /*Transitions*/
  --trsMain:0.15s ease-out;
  --trsHover:0.085s ease;
}

/*Make images easier to work with*/
img{
  display:block;
  width: 100%;
  height: 100%;
  object-fit:cover;
  image-rendering: -webkit-optimize-contrast;
}

/*Remove list styling*/
ul{
  list-style: none;
}

a{
  text-decoration: none;
  color:var(--clrDarkBlue);
}

a:visited{
  color:#000
}

small{
  display: block;
}

.container{
  max-width: var(--widthDesktop);
  margin: auto;
}

/*Active class*/
.active::before{
  content: '';
  position: absolute;
  top:0;
  left:0;
  height: 100%;
  width: 100%;
  background: rgba(255,255,255, 0.6);
  border: 2px solid var(--clrLightGrey);
  border-radius: 10px;
}

.visible{
  visibility: visible;
}

/*Input elements*/
input, textarea, select{
  font-family: var(--ffMain);
} 

/*Buttons*/
.btn{
  border:none;
  cursor: pointer;
  font-family: inherit;
  border-radius: 5px;
  color:#fff;
  font-weight: 400;
  background: none;
  transition: all var(--trsMain);
}

.btn-primary{
  padding: 18px 76px;
  font-size: 16px;
  line-height: 16px;
  background: var(--clrDarkBlue);
}

.btn-primary:hover,
.btn-submit:hover {
  background: var(--clrMediumBlue)
}

.btn-submit{
padding: 14px 50px;
font-size: 14px;
line-height: 14px;
background: var(--clrDarkBlue);
border-radius: 5px;
}

.btn-navigate{
  padding: 10px 15px;
  font-size: 12px;
  line-height: 12px;
  border-radius: 5px;
  background: var(--clrBlueGrey);
  width: 80px;
}

.btn-medium{
  padding: 12px 18px;
  font-size: 14px;
  line-height: 14px;
  border-radius: 5px;
  background: var(--clrDarkBlue);
}

.btn-navigate:hover{
  background: #8b8f97;
}

.btn:disabled{
  cursor:default;
}

.btn-primary:disabled {
  background:var(--clrLightGrey)
}

.btn-block{
  width: 100%;
}

/*Typography*/
h1{
  font-size: 30px;
}

h1, h2, h3, h4, h5, p{
  font-weight: 500;
  color:var(--clrVeryDarkBlue)
}

.text-dark{
  font-weight: 700;
}

.text-light{
  color:var(--clrLightGrey);
  font-weight: 700;
}

.text-large{
  font-size: 44px;
  line-height: 48px;
}

.text-medium{
  font-size: 18px;
  line-height: 22px;
}

.text-small{
  font-size: 14px;
  line-height: 14px;
  font-weight: 500;
}

.border{
 box-shadow: 0 0 0 1px var(--clrLightGrey);
}

/*Shapes*/
.loader{
    width:50px;
    height:50px;
    border-radius:50%;
    background:conic-gradient(#0000 10%,#766DF4);
    mask:radial-gradient(farthest-side,#0000 calc(100% - 8px),#000 0);
    animation:s3 1s infinite linear;

  @keyframes s3 {to{transform: rotate(1turn)}}
}
