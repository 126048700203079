@import url('./design-system.css');

*,
*::after,
*::before {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body{
  font-family: var(--ffMain);
  font-size: 16px;
  font-weight: 400;
  height: 100vh;
  width: 100vw;
}

/*Disable Scrolling on Mobile Menu*/
body.mobile-menu-open{
  position: fixed;
}

/*LightBox*/
.lightbox-overlay{
 display: none;
 position: fixed;
 top:0;
 left:0;
 width: 100vw;
 height: 100vh;
 background: rgba(0,0,0,0.0);
 z-index: 1;
}

.lightbox-open{
  display: flex;
  background: rgba(0,0,0,0.9);
}

.lightbox-img-wrapper{
  width: 550px;
  height: 550px;
  position: absolute;
  top: 133px;
  left:50%;
  transform: translateX(-50%);
  border-radius: 10px;
  z-index: 2;
}

.icon-prev-wrapper,
.icon-next-wrapper {
  position: absolute;
  width: 56px;
  height: 56px;
  background: #fff;
  top:50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
}

.icon-next-wrapper{
  right: 0;
  transform: translateX(50%);
}

.icon-close{
  position: absolute;
 z-index: 5;
}

.icon-close{
  top:-48px;
  right:0;
  transform: scale(1.3);
  cursor: pointer;
}

.lightbox-thumbnails-wrapper{
  position: absolute;
  bottom:-40px;
  left:50%;
  transform: translate(-50%,100%);
  display: flex;
  gap:30px;
}

.lightbox-thumbnail-wrapper{
  width: 88px;
  height: 88px;
  cursor: pointer;
  position: relative;
}

.lightbox-img-wrapper img{
  border-radius: 10px;
}

.lightbox-thumbnail-wrapper:not(.active)::after{
  content: '';
  position: absolute;
  top:0;
  left:0;
  height: 100%;
  width: 100%;
  background: rgba(255,255,255, 0.45);
  border-radius: 10px;
  opacity: 0;
  transition: opacity .3s ease;
}

.lightbox-thumbnail-wrapper:not(.active):hover::after{
  opacity: 1;
}

.close-icon{
  position: absolute;
  top:0;
  right:0;
  z-index: 5;
}

/*Navbar*/
.navbar-wrapper{
  height: auto;
  margin-top: 28px;
  margin-bottom: 40px;
  justify-content: space-between;
  position: relative;
}

.navbar-wrapper::after{
  content:'';
  position:absolute;
  bottom:-15px;
  left:0;
  width: 100%;
  height: 1px;
  background: var(--clrSeperator);
}

.navbar-wrapper,
.navbar-brand,
.navbar-menu,
.navbar-user{
  display: flex;
  align-items: center;
}

.mobile-menu-overlay {
 display: none;
}

.logo-wrapper{
  margin-right: 56px;
}

.navbar-menu{
  gap:32px;
}

.mobile-menu-icon-wrapper{
  display: none;
}

.navbar-menu a{
  color:var(--clrBlueGrey);
  font-size: 15px;
  font-weight: 400;
  line-height: 26px;
  transition: var(--trsMain);
  position: relative;
}

  a.current-nav{
  color:var(--clrVeryDarkBlue);
  font-weight: 700;
}

.navbar-menu a:hover{
  color:var(--clrVeryDarkBlue);
}

.navbar-menu a::after{
  content:'';  
  position: absolute;
  left:50%;
  bottom: -30px;
  width: 0;
  height: 4px;
  background: var(--clrOrange);
  transform: translateX(-50%);
  transition: var(--trsMain);
}

.navbar-menu a:hover::after{
  width:58px;
}

.navbar-user{
  gap:46px;
  position: relative;
}

/*Navbar User Dropdown*/
.user-profile-wrapper{
  position: relative;
  cursor: pointer;
  padding: 10px;
}

.user-dropdown-menu-wrapper{
  position: absolute;
  width: 250px;
  height: auto;
  left:-155px;
  top:54px;
  padding: 10px;
  background: var(--clrVeryLightGrey);
  border:1px solid var(--clrLightGrey);
  z-index: 2;
}

.arrow-up{
  position: absolute;
  top:-12px;
  right:50px;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 12px solid var(--clrLightGrey)
}

.user-dropdown-menu li {
  padding: 5px 0;
  width: auto;
  font-size: 15px;
  cursor: pointer;

}

.user-dropdown-menu li:hover{
  font-weight: 700;
}

.cart-icon-wrapper{
  position: relative;
}

.cart-qty-display{
  position: absolute;
  width: 24px;
  height: 20px;
  background: var(--clrFuchsia);
  color:#fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  line-height: 12px;
  border-radius: 50%;
  top:-10px;
  right:-10px;
}

/*Product Page*/
.product-page-wrapper{
  display: grid;
  grid-template-columns: repeat(4,1fr);
  gap:10px;
  margin: auto;
  padding-bottom: 20px;
  position: relative;
}

/*Product Card*/
.product-card-wrapper{
  padding: 12px;
  border:1px solid var(--clrLightGrey);
}

.product-card-img-wrapper{
  margin-bottom: 24px;
}

.product-details-wrapper{
  display:flex;
  gap:50px;
  margin: auto;
}

/*Product Details*/
.product-detail-image-wrapper{
  width: 445px;
  height: 445px;
  margin-bottom: 30px;
  cursor: pointer;
  overflow: hidden;
  position: relative;
  background-color: #f6f6f6;
}

.zoom-slider-container{
  position: absolute;
  top:110px;
  right:-80px;
  transform: rotate(-90deg);
  width: 200px;
}

.zoom-slider {
  width: 100%;
  appearance: none;
  height: 15px;
  border-radius: 10px;
  background: #d3d3d3;
  opacity: 0.5;
  transition: opacity var(--trsMain);
}

.zoom-slider:hover{
  opacity: 1;
}

.zoom-slider::-webkit-slider-thumb{
  appearance: none;
  height: 15px;
  width: 15px;
  background: var(--clrDarkBlue);
  border-radius: 50%;
}


.product-detail-image-wrapper img{
  transform: scale(var(--zoomRate));
}

.product-card-body .company-name{
  margin-bottom: 14px;
}

.product-card-body .product-name{
  font-weight: 500;
  margin-bottom: 6px;
}

.product-card-body .product-price{
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 4px;
  line-height: 16px;
}

.card-categories{
  display: flex;
  gap:10px
}

.product-category{
  color: var(--clrBlueGrey);
  /*border:1px solid var(--clrLightGrey);*/
  box-shadow: 0 2px 4px var(--clrLightGrey);
  padding: 5px;
  margin-bottom: 18px;
}

.color-thumbnails-wrapper{
  display: flex;
  gap:10px;
  cursor:pointer
}

.color-thumbnail-wrapper{
  width: 50px;
  height: 40px;
}

.color-thumbnail-wrapper img{
  border-radius: 5px;
}

.product-thumbnails{
  display: flex;
  max-width: 445px;
  justify-content: space-between;
}

.thumbnail-wrapper{
  width: 88px;
  height: 88px;
  position: relative;
  cursor: pointer;
}


.thumbnail-wrapper::after{
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top:0;
  left:0;
  background: rgba(255,255,255, 0.6);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.thumbnail-wrapper:hover::after{
  opacity: 1;
}

.product-main-image-wrapper img,
.thumbnail-wrapper img{
border-radius: 10px;
}

/*Product Info Section*/
.product-info-wrapper{
  display: flex;
  flex-direction: column;
  width: 445px;
 
}

.company-name{
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 2px;
  text-transform: uppercase;
  color:var(--clrOrange);
  margin-bottom: 27px;
}

.product-name{
margin-bottom: 32px;
}

.product-description{
  font-weight: 400;
  line-height: 26px;
  color:var(--clrBlueGrey);
  margin-bottom: 24px;
}

.price-wrapper{
 margin: 10px 0;
}
.product-price{
  font-size: 28px;
  line-height: 35px;
  margin-bottom: 10px;
}

.discount-tab{
 margin-left: 16px;
 font-size: 16px;
 line-height: 20px;
 color:var(--clrOrange);
 padding: 4px 8px;
 background: var(--clrLightOrange);
 border-radius: 6px;
}

.original-price{
  font-size: 16px;
  line-height: 26px;
  text-decoration: line-through;
}

.sizes-wrapper{
  margin-bottom: 20px;
}

.size-title{
  margin-bottom: 10px;
}

.sizes-inner-wrapper{
  display:grid;
  grid-template-columns: repeat(3,1fr);
  gap:5px;
  max-width: 200px;
}

.sizes-inner-wrapper.no-size{
  border:1px solid red;
  border-radius: 3px;
}

.size-card{
  text-align: center;
  padding: 3px;
  border:1px solid var(--clrLightGrey);
  border-radius: 3px;
  transition: all var(--trsHover);
}

.size-card:hover,
.size-card.selected
{
  background: var(--clrBlueGrey);
  color:#fff;
}

.cart-control-wrapper{
  display: flex;
  gap:16px;
}

.size-alert{
  display: none;
}

.size-alert.visible{
  display: block;
  color:red;
  margin: 5px 0;
}

.qty-wrapper{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 157px;
  height: 56px;
  background: var(--clrVeryLightGrey);
  border-radius: 10px;
}

.icon-cart{
margin-right: 12px;
vertical-align:bottom;
transform: scale(0.8);
}

.qty-info{
  margin: 0 45px;
  font-weight: 500;
}

/*Shopping Cart*/
.cart-wrapper{
  position: absolute;
  width: 360px;
  max-height: 500px;
  top:65px;
  right:0px;
  background: #fff;
  padding: 24px 24px 32px 24px;
  border-radius: 10px;
  box-shadow: 0px 20px 50px -20px rgba(29, 32, 38, 0.50);
  z-index: 2;
  overflow-y: scroll;
  overflow-x: hidden;
}

.cart-notification{
  line-height: 26px;
  font-weight: 700;
  text-align: center;
  color:var(--clrBlueGrey);
  position: absolute;
  bottom:15%;
  left:50%;
  transform: translateX(-50%);
}

.card-header{
  display: flex;
  justify-content: space-between;
}

.card-header .btn,
.cart-title{
  font-size: 16px;
  color:var(--clrVeryDarkBlue);
  font-weight: 700;
  position: relative;
  margin-bottom: 52px;
}

.cart-title::after{
  content:'';
  position:absolute;
  width: 360px;
  height: 1px;
  left:-24px;
  bottom:-27px;
  background:var(--clrSeperator);
}

.cart-item-details-wrapper{
display: grid;
grid-template-columns: 2fr 6fr 1fr;
gap:10px;
margin-bottom: 20px;
}

.cart-item-image-wrapper{
  width: 50px;
  height: 50px;
  align-self: flex-start;
  margin-right: 10px;
}

.cart-item-image-wrapper img{
  border-radius: 4px;
}

.cart-item-name,
.cart-item-price,
.cart-item-color,
.cart-item-size
{
  color:var(--clrBlueGrey);
}

.cart-color-size-wrapper{
  margin: 5px 0;
}

.cart-item-color,
.cart-item-size {
  font-size: 14px;
}

.cart-item-price span{
  font-weight: 700;
  color:var(--clrVeryDarkBlue);
}

.stock-info-wrapper{
  display: flex;
  align-items: center;
  color:var(--clrBlueGrey);
  font-size: 14px;
  gap:20px;
  margin-top: 6px;
}

.stock-info-wrapper select{
  border: none;
  background: var(--clrVeryLightGrey)
}

.cart-trash-container{
  justify-self:right;
  align-self:center;
}

.cart-delete-img-container {
  height: 16px;
  width: 16px;
}

/*Register Form*/
.form-wrapper{
  width: 400px;
  margin-top:75px;
  padding-bottom: 50px;
}

.btn-navigate-profile{
margin-bottom: 20px;
}
 
.alert-wrapper{
  padding:10px;
  margin-bottom: 15px;
}

.alert-wrapper.error{
   background: var(--clrLightOrange);
  border:1px solid var(--clrOrange);
}

.alert-wrapper.warning{
  background:var(--clrWarning);
  border: 1px solid var(--clrLightOrange);
}

.alert-wrapper.warning p {
  color:var(--clrDarkBlue);
  font-weight: 400;
}

.alert-wrapper.success {
  background: var(--clrGreen);
  border:1px solid var(--clrLightOrange);
}

.alert-wrapper.success p{
  color:var(--clrVeryLightGrey);
}

.alert-wrapper p{
  color:var(--clrDarkBlue);
  font-size: 15px;
  font-weight: 500;
}

.form-wrapper h1{
  font-size: 24px;
  text-align: center;
  margin-bottom: 20px;
}

.form-wrapper label{
  color:var(--clrBlueGrey);
  font-size: 14px;
  margin-bottom: 2px;
}

.form-wrapper input,
.form-wrapper select{
  height: 30px;
  border:1px solid var(--clrLightGrey);
  padding: 5px 10px;
  font-size: 14px;
}

.form-wrapper input:focus,
.form-wrapper select:focus {
  border:none;
  outline: 2px solid var(--clrDarkBlue);
}

.form-wrapper input.alert,
.form-wrapper select.alert{
  border:none;
  outline: 2px solid red;
}

.form-group{
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
  position: relative;
}

.form-group i{
  position: absolute;
  right:10px;
  top:53%;
}

.form-group:last-of-type{
  margin-bottom: 15px;
}

.form-redirect-wrapper{
  margin-top: 5px;
}

.form-redirect-wrapper p{
  font-size: 14px;
}

.form-group-address{
  border:1px solid var(--clrLightGrey);
  padding: 5px 0 ;
  margin-bottom: 15px;
}

.form-group-address h4{
  text-align: center;
}

.form-group-address input,
.form-group-address select {
  height:30px;
  width: 80%;
  margin: auto;
}

.form-group-address label {
  margin-left:10%;
}

.form-redirect-wrapper a {
 font-weight: 700;
}

/*Account Page*/
.account-page-wrapper{
  width: var(--widthDesktop);
}
.account-page{
  display: flex;
  gap:20px;
  margin-top: 20px;
}

/*Account Card*/
.account-card-wrapper{
  width: 300px;
  height: 120px;
  padding: 20px;
  border:1px solid var(--clrLightGrey);
}

.account-card-wrapper header{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.account-card-wrapper h3{
  font-weight: 500;
}

.account-card-wrapper p{
  color:var(--clrBlueGrey);
  margin-top: 10px;
  font-size: 15px;
}

.account-card-wrapper:hover{
  background: var(--clrVeryLightGrey);
}

.account-card-wrapper:hover p{
  color:var(--clrDarkBlue)
}

/*Checkout Page*/
.checkout-page-wrapper{
  width: var(--widthDesktop);
  margin: auto;
}

.checkout-page-wrapper h1{
  display: block;
  padding-bottom: 10px;
  border-bottom: 1px solid var(--clrVeryDarkBlue);
}

.checkout-page-wrapper h2{
  text-align: center;
  margin: 20px;
}

table{
  width: 100%;
  border-collapse: collapse;
}

.checkout-header, .checkout-item-row {
  display: grid;
  grid-template-columns: 4fr 1fr 1fr 1fr 1fr;
  padding: 10px 30px;

}

.checkout-item-row::after {
  content: '';
  width: 200%;
  height: 1px;
  margin-top: 10px;
  border-bottom: 1px solid var(--clrSeperator);
}

.checkout-header th{
  color:var(--clrBlueGrey);
  font-weight: 500;
  font-size: 18px;
  text-align: left;
}

.checkout-header {
  background: linear-gradient(var(--clrVeryLightGrey), #dae6fc);
  margin-bottom: 20px;
}

.checkout-item-row td{
  display: flex;
  align-items: center;
  gap:20px;
  color:var(--clrDarkBlue);
  padding-bottom: 10px;
  margin-bottom: -10px;
}

.checkout-item-description{
  flex-direction: column;
}

.checkout-item-description small{
  margin-bottom: 2px;
}

.checkout-item-color-size-wrapper{
  margin: 5px 0;
}

.checkout-item-row td p {
  color:var(--clrDarkBlue)
}



.checkout-item-row td:first-child p:hover{
  text-decoration: underline;
}

.checkout-item-row td:last-child{
  justify-content: center;
}

tbody img{
  width: 90px;
  height: 90px;
}

tbody select {
  border:1px solid var(--clrLightGrey);
  padding: 2px;
  border-radius: 5px;
}

.cart-item-delete{
  width: 20px;
  height: 20px;
}

.total-info-wrapper{
  display: flex;
  flex-direction: column;
  gap:10px;
  align-items: flex-end;
  padding:0 30px;
  margin-top: 30px;
  color:var(--clrDarkBlue);
  font-weight: 500;
}

.total-info-wrapper p:nth-child(2) span.success{
  color:var(--clrDarkGreen);
}

.total-info-wrapper p:last-of-type {
  font-size: 22px;
  padding: 10px 0;
}

.total-info-wrapper p:last-of-type span{
  font-weight: 700;
}

/*Payment Page*/
.payment-page-wrapper{
  width: var(--widthDesktop);
  margin: auto;
}

.payment-page-wrapper .btn-navigate{
  margin-bottom: 20px;
}

.place-order-btn{
  margin: 20px 0;
}

.shipping-billing-wrapper{
  display: flex;
  gap:50px;
}


/*Shipping Summary*/
.shipping-summary-wrapper{
  display: flex;
  flex-direction: column;
  gap:10px;
  border: 1px solid var(--clrLightGrey);
  border-radius: 5px;
  padding: 20px;
  max-width: 500px;
  margin-bottom: 25px;
}

.summary-row {
  display: grid;
  grid-template-columns:1fr 3fr 1fr;
}

.summary-row:first-child{
  border-bottom: 1px solid var(--clrSeperator);
  padding-bottom: 10px;
}

.summary-row h4{
  font-weight: 400;
  font-size:16px;
  letter-spacing: -0.25px;
  color:var(--clrBlueGrey)
}

.summary-row p{
  font-weight: 400;
}

.summary-edit-btn{
  justify-self:end;
}

/*Payment Form*/
.payment-form-wrapper{
  max-width: 500px;
  display: flex;
  flex-direction: column;
}

.shipping-billing-wrapper .btn-navigate{
  margin-bottom: 20px;
}

.form-section-title{
  margin-bottom: 10px;
}

.form-section-title h3{
  font-size:18px;
  letter-spacing: -0.25px;
}

.form-section-title p{
  font-size: 15px;
}

.form-section-title .alert-wrapper {
  margin-top: 10px;
  padding: 15px 10px;
}

.form-section-title .alert-wrapper p {
  font-size: 14px;
}

.payment-method-wrapper{
  border:1px solid var(--clrLightGrey);
  border-radius: 5px;
  margin-bottom: 25px;
}

.payment-method-form-group {
 display: flex;
 align-items: center;
 padding: 15px; 
}


.payment-method-form-group {
  display: flex;
  justify-content: space-between;
}

.card-icons-wrapper{
  display: flex;
  gap:10px
}

.payment-method-form-group:first-child {
  border-bottom: 1px solid var(--clrLightGrey);
}

.payment-form-wrapper input[type='radio']{
  margin-right: 10px;
}

.payment-method-form-group label{
  font-size: 15px;
  letter-spacing: -0.25px;
}

.credit-card-form-wrapper,
.paypal-button-wrapper {
  display: none;
}

.payment-form-wrapper input[type='text'] {
  display: block;
  width: 100%; 
  border-radius: 5px;
  margin: auto;
  padding: 10px;
  font-size: 14px;
  border:1px solid var(--clrLightGrey)
}

.card-number-wrapper{
  position: relative;
}

.lock-icon-wrapper{
  position: absolute;
  right:10px;
  top:50%;
  transform: translateY(-50%);
}

.expiration-security-wrapper{
  display: grid;
  grid-template-columns: 10fr 9fr;
  gap:10px;
  position: relative;
}

.security-code-wrapper{
  position: relative;
}

.security-code-tip-wrapper, .encryption-tip-wrapper {
  position: absolute;
  top:-115px;
  right:-55px;
  width: 150px;
  height: auto;
  text-align: center;
  line-height: 16px;
  padding: 10px;
  background-color: rgba(44, 44, 44, 0.95);
  border-radius: 3px;
  color:#fff;
  font-size: 12px;
  letter-spacing:-0.3px
}

.encryption-tip-wrapper{
  top:-50px;
}

.tip-icon-holder{
  position: absolute;
  right: 10px;
  top:50%;
  transform: translateY(-50%);
}

/*Billing Form*/
.billing-address-form-wrapper{
  display: none;
}

.billing-address-form-group-wrapper{
  border:1px solid var(--clrLightGrey);
  border-radius: 5px;
  }
  
.credit-card-form-wrapper.visible, 
.billing-address-form-wrapper.visible,
.paypal-button-wrapper.visible
{
  display: flex;
  flex-direction: column;
  gap:18px;
  background-color: var(--clrVeryLightGrey);
  border-radius: 5px;
  padding:20px 15px;
}

.alert-validation{
  color: red;
  position: absolute;
  font-size: 11px;
  top:-15px;
  left:2px;
}

.paypal-button-wrapper.visible{
margin-left: 20px;
}

.billing-address-form-wrapper select{
  border:1px solid var(--clrLightGrey);
  padding: 10px 50px 10px 10px;
  border-radius: 5px;
  width: 100%
}

.first-last-name{
  display: grid;
  grid-template-columns: repeat(2,1fr);
  gap:10px;
}

.billing-address-form-group{
  padding: 15px;
}

.billing-address-form-group:first-child{
  border-bottom: 1px solid var(--clrLightGrey);
}

.form-group-billing:last-child {
  display: grid;
  grid-template-columns: repeat(3,1fr);
  gap:10px;
}

.payment-form-wrapper footer {
margin-top: 20px;
padding-bottom: 40px;
align-self: flex-end;
}

/*Payment Page Order Summary*/
.order-summary-wrapper{
  width: 500px;
  display: flex;
  flex-direction: column;
  gap:20px;
}

.order-items-wrapper{
  border-bottom: 1px solid var(--clrLightGrey);
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  gap:20px;
}

.order-item-wrapper{
  display: grid;
  grid-template-columns: 1fr 4fr 1fr;
  gap:30px;
  align-items:center;
}


.order-item-img-wrapper{
  position: relative;
  width: 80px;
  height: 80px;
}

.order-item-qty{
  position: absolute;
  top:-5px;
  right:-5px;
  background:var(--clrBlueGrey);
  color:#fff;
  font-size: 10px;
  height:20px;
  width: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.order-item-total{
  justify-self: end;
}

.order-summary-subs-wrapper{
  display: flex;
  flex-direction: column;
  gap:10px;
  border-bottom: 1px solid var(--clrLightGrey);
  padding-bottom: 20px;
}

.order-total-row{
  display: flex;
  justify-content: space-between;
}

.order-summary-total-wrapper{
  display: flex;
  justify-content: space-between;
}

.order-summary-total-wrapper span{
  color:var(--clrBlueGrey);
  font-size: 12px;
  margin-right: 5px;
  font-weight: 500;
  letter-spacing: 0.25px;
}

.total-bill{
  font-weight: 500;
  font-size: 24px;
  letter-spacing: 1px;
}

/*Order Confirmation Page*/
.order-confirmation-page-wrapper {
  max-width: var(--widthDesktop);
  margin: auto;

}

.order-confirmation-header{
  display: flex;
  align-items: center;
  justify-content: center;
  color:#fff;
  font-size: 26px;
  letter-spacing:0.5px;
  background: var(--clrGreen);
  height:100px;
  margin-bottom: 50px;
}

.order-confirmation-wrapper{
  max-width: var(--widthDesktopMedium);
  display: grid;
  grid-template-columns:repeat(2, 1fr);
  gap:50px;
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid var(--clrSeperator);
}

.order-confirmation-wrapper{
  margin-left:0;
}

.order-confirmation-wrapper h3{
  color:var(--clrBlueGrey);
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid var(--clrSeperator);
}

.confirmation-row{
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
}

.confirmation-row:last-of-type {
  margin-top: 15px;
  font-size: 22px;
}

.confirmation-row:last-of-type h4{
  font-weight: 700;
}

.delivery-info-wrapper{
text-align: center;
}

.delivery-info-wrapper h4{
  color:var(--clrBlueGrey);
  margin-bottom: 10px;
}

.delivery-info-wrapper p{
  font-weight: 400;
  font-size: 15px;
}

.delivery-info-wrapper p:first-child{
  font-weight: 500;
}

.order-item-confirmation-details-wrapper{
  display: flex;
  gap:20px;
  margin-bottom: 8px;
}


.order-item-confirmation-image-wrapper{
  width: 65px;
  height: 65px;
}

.order-item-confirmation-name-wrapper{
  font-size: 15px;
}

.order-item-confirmation-name-wrapper li:first-child{
  font-weight: 500;
  margin-bottom: 5px;
}

.order-item-confirmation-name-wrapper li:not(:first-child){
 font-size: 14px;
}

/*Order History Page*/
.order-history-page-wrapper{
  display: flex;
  flex-direction: column;
  gap:20px;
  margin-top: 30px;
}

.order-history-card-wrapper {
  max-width: 960px;
}

.order-history-card-wrapper:hover{
  background: var(--clrVeryLightGrey);
}

.order-history-details-wrapper {
  position: relative;
}

.order-history-details-wrapper::before {
  content:'';
  position: absolute;
  height: 100%;
  width: 1px;
  background: var(--clrLightGrey);
  right:-20px;
}

.order-history-details-wrapper h4,
.order-history-items h3{
  margin-bottom: 5px;
}

.order-history-details-wrapper li{
  font-size: 15px;
}
.order-history-details-wrapper li:nth-child(3){
  font-weight: 500;
  margin-top: 5px;
  font-size: 18px;
}

.order-history-card{
  max-width: 840px;
  border:1px solid var(--clrLightGrey);
  padding:20px;
  display: grid;
  grid-template-columns: 2fr 4fr;
  gap:50px;
}

.order-history-image-wrapper{
  max-width: 50px;
  max-height: 50px;
}

.order-history-row{
  display: flex;
  gap:15px;
  margin-bottom: 10px;
}

/*Breadcrumbs*/
.breadcrumbs-wrapper{
  display: flex;
  margin-bottom: 30px;
}

.breadcrumbs-wrapper a.disabled{
  cursor: default;
  color:var(--clrLightGrey);
  font-weight: 300;

}

.breadcrumbs-wrapper a{
 background: var(--clrBlueGrey);
 padding: 10px 20px;
 color:#000;
 font-weight: 500;
 position: relative;
 overflow: hidden;
}

.breadcrumbs-wrapper a:before{
  content: '';
  position: absolute;
  height: 40px;
  width: 40px;
  border:solid #fff;
  border-width: 2px 2px 0 0;
  top:0;
  right: 8px;
  transform: rotate(45deg);
}

.breadcrumbs-wrapper a:nth-child(4):after{
  content: '';
  position: absolute;
  width: 40px;
  height: 40px;
  background: #fff;
  right: -28px;
  transform: rotate(-45deg);
  top:-20px;
}

.breadcrumbs-wrapper a:nth-child(4):before{
  content: '';
  position: absolute;
  width: 40px;
  height: 40px;
  background: #fff;
  right: -28px;
  transform: rotate(-45deg);
  top:20px;
}

/*Search Bar*/
.search-bar-wrapper .form-group {
  width: 265px;
}

.search-bar-wrapper .form-group input[type='text']{
  padding: 10px 5px;
  border: 1px solid var(--clrBlueGrey);
}

.search-bar-wrapper .form-group i {
 top:50%;
 transform: translateY(-50%);
}

/*Sort Container*/
.sort-container{
  display: flex;
}

.sort-container > * {
  padding:0 10px;
  border-right: 1px solid var(--clrLightGrey)
}

.sort-container > :first-child {
  padding-left: 0;
}

.sort-container > :last-child {
  border:none
}

.filter-options-wrapper{
  display: flex;
  flex-direction: column;
}

.filter-options-form-group {
  position: relative;
  display: flex;
  align-items: center;
  gap:4px;
}

.filter-options-form-group:nth-child(1){
  border-bottom: 1px solid var(--clrLightGrey);
  padding-bottom: 3px;
  margin-bottom: 3px;
}

.filter-options-form-group label{
  font-weight: 300;
}

.filter-options-form-group label.selected{
  font-weight: 500;
}

.sort-container select {
  padding: 3px;
  border-radius: 5px;
}

/*RESPONSIVE STYLE*/
/*Desktop Medium*/
@media screen and (max-width:1470px){
  .container{
  max-width: var( --widthDesktopMedium);
  }

  .navbar-menu{
    gap:26px
  }

  .navbar-user {
    gap:20px
  }

  .product-page-wrapper {
    grid-template-columns: repeat(3, 1fr)
  }

  .checkout-page-wrapper {
    width: var(--widthDesktopMedium);
  }

  .product-details-wrapper,
  .shipping-billing-wrapper{
    gap:30px;
  }

  .payment-page-wrapper{
    width: var(--widthDesktopMedium);
  }

  /*Account Page Desktop Medium*/
  .account-page-wrapper {
    width: var(--widthDesktopMedium);
  }

  .account-page{
    justify-content: space-between;
  }

  .payment-form-wrapper{
    max-width: 420px;
  }

.shipping-billing-wrapper {
  gap:25px
}

.order-confirmation-header {
  margin-bottom: 30px;
}
}

/*Tablet*/
@media screen and (max-width:980px){
  .container{
    max-width: var(--widthTablet);
  }
  
  .navbar-menu {
    gap:18px
  }

  .navbar-menu a{
    font-size: 15px;
  }

  .logo-wrapper{
    margin-right: 40px;
  }

  .navbar-user {
    gap:10px
  }

  .product-page-wrapper {
  grid-template-columns:repeat(2,1fr);
}

.product-details-wrapper {
  gap:20px
}

.text-large {
  font-size: 42px;
}

.product-detail-image-wrapper {
  width: 350px;
  height: 350px;
  margin-bottom: 20px;
}

.price-wrapper{
  margin:0;
}

.company-name, .product-name, 
.product-description, .sizes-wrapper {
  margin-bottom: 15px;
}

.size-title{
  margin-bottom: 7px;
}

.cart-control-wrapper{
  gap:10px;
}

.qty-wrapper{
  width: 140px;
  padding: 0 10px;
}

.btn-primary{
  padding: 0 55px;
}

.cart-wrapper .btn-primary{
  padding: 18px 55px;
}

.product-card-wrapper {
  padding: 10px;
}

.checkout-page-wrapper {
  width: var(--widthTablet);
}

/*Account Page Tablet*/
.account-page-wrapper {
  width: var(--widthTablet);
}

.account-card-wrapper {
  width: 230px;
}

/*Tablet Profile Page*/
.btn-profile{
  padding: 14px 50px;
}

/*Tablet Shipping Payment Page*/ 
.payment-page-wrapper{
  width: var(--widthTablet);
}

.shipping-summary-wrapper {
  padding: 10px;
}

.summary-row h4,
.summary-row p {
  font-size: 15px;
}

.summary-row h4{
  margin-right: 10px;
}

.shipping-billing-wrapper {
  gap:20px
}

.order-confirmation-header {
  margin-bottom: 20px;
}

.order-confirmation-wrapper h3{
  margin-bottom: 15px;
  padding-bottom: 15px;
}

}

/*MOBILE*/
@media screen and (max-width:767px){
  .container {
    max-width: 350px;
    margin: auto;
  }
  
  .search-bar-wrapper .form-group input {
    width: 350px;
  }

  .search-bar-wrapper .form-group i{
    right: -25%;
  }
  
 /*Mobile Navbar & Menu*/
 .mobile-menu-overlay{
  display: block;
  position: fixed;
  height:1260px;
  width: 100vw;
  background: rgba(0,0,0,0.0);
  z-index: 2;
  transform: translateX(-110%);
  transition: background 0.8s 0.35s ease-in-out, transform 0.35s ease-in-out;
 }

/*Menu Open*/
.mobile-menu-overlay.open {
 background: rgba(0,0,0,0.7);
 transform: translateX(0%);
 overflow: hidden;
 position: fixed;
 
}

 .mobile-menu-wrapper{
  position: absolute;
  height:100%;
  width: 250px;
  background:#fff;
  transform: translateX(-100%);
 }

 .mobile-menu-wrapper.open {
  transform: translateX(0%);
 }

 .mobile-menu {
  position: absolute;
  top:580px;
  display: flex;
  flex-direction: column;
  gap:20px;
  padding: 40px 0 0 5px;
}

 .navbar-wrapper {
  justify-content: space-between;
  margin-top: 18px;
 }

 .navbar-brand{
  display: flex;
  gap:12px;
  align-items: baseline;
 }

.close-icon-wrapper{
  height: 18px;
  width: 2px;
  transform: rotate(45deg);
  position: relative;
  margin:0 0 50px 5px;
  cursor: pointer;
}

.close-icon-wrapper::before{
  position: absolute;
  content:'';
  height:100%;
  width: 100%;
  background: var(--clrVeryDarkBlue);
  transform: rotate(90deg);
}

.close-icon-wrapper::after{
  position:absolute;
  content:'';
  height: 100%;
  width: 100%;
  background: var(--clrVeryDarkBlue);
}

 .mobile-menu-icon-wrapper{
  display: block;
  cursor: pointer;
  margin-right: 5px;
 }

 .navbar-menu {
  display: none;
 }

.logo-wrapper{
  margin: 0px;
  width: 125px;
}

 .cart-icon-wrapper {
  height:20px;
  width: 20px;
 }

 .cart-qty-display {
  width: 20px;
  height: 16px;
  font-size: 10px;
  top:-6px;
 }

 .navbar-user {
  gap:5px;
 }

 .user-profile-wrapper h4{
  font-size: 14px;
 }

 /*Mobile Searchbar*/
 .search-bar-wrapper {
  margin-top: 8px;

 }

/*Mobile Product Page*/
  .breadcrumbs-wrapper{
    display: none;
    margin-bottom: 15px;
  }

  .breadcrumbs-wrapper a{
    font-size: 14px;
  }
  .product-page-wrapper {
    grid-template-columns:1fr;
  }

  .product-card-wrapper {
    border: none;
    padding: 0;
    padding-bottom: 10px;
    margin-bottom: 10px;
    position: relative;
  }

  .product-card-wrapper::before {
    content: '';
    position: absolute;
    width: 90%;
    transform: translateX(-50%);
    left: 50%;
    bottom: 0;
    height: 1px;
    background: var(--clrLightGrey);
  }

  .product-details-wrapper {
    display: flex;
    flex-direction: column;
    gap:20px;
  }

  .product-detail-image-wrapper {
    width: 100%;
    height: 300px;
    margin-bottom: 15px;
  }
  
  .thumbnail-wrapper {
    height: 80px;
    width: 80px;
  }

  .product-info-wrapper {
    width: 100%;
    margin-bottom: 40px;
  }

  .company-name, .product-name,.product-description {
    margin-bottom: 10px;
  }

  .company-name {
    font-size: 12px;
  }

  .text-large{
    line-height: 32px;
  }

  .product-description {
    font-size: 15px;
    line-height: 25px;
  }

  .price-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
   }

   .original-price {
    align-self: flex-end;
   }

  .product-price {
    margin-bottom: 0px;
  }

  .product-name{
    font-size: 28px;
    line-height: 1;
  }

  .size-title {
    font-size: 15px;
  }

  .cart-control-wrapper {
    flex-direction: column;
  }
  
  .qty-wrapper{
    width: 100%;
    justify-content: space-between;
    padding: 0 24px;
  }
  .btn-primary {
    height: 56px;
    font-weight: 400;
    font-size: 13px;
    padding: 0 72px;
  }

  /*Mobile Account Page*/
  .account-page-wrapper {
    margin-top: 30px;
  }

  .account-page {
    flex-direction: column;
    gap:15px;
    margin-top: 10px;
  }

  .account-card-wrapper {
    width: 100%;
  }

  /*Mobile Change Password & Edit Profile Page*/
  .profile-wrapper {
    margin-top: 20px;
  }

  .profile-wrapper h1{
    text-align: left;
    margin-bottom: 10px;
  }

  .btn-navigate-profile{
   margin-bottom: 10px;
  }

  .btn-profile {
    padding: 14px 50px;
    height: 42px;
    width: 147px;
  }

  /*Mobile Checkout Page*/
  .checkout-page-wrapper {
    width: 350px;
  }

  .checkout-page-wrapper h1 {
    font-size: 28px;
  }

  .checkout-header {
   margin-bottom: 10px;
   padding: 10px 5px;
  }

  .checkout-page-wrapper h2{
    text-align: left;
    margin: 10px 0;
  }
  
  .checkout-item-row {
    padding:0;
    gap:7px;
  }

  .checkout-item-row::after {
    margin-top: -10px;
    margin-bottom: 10px;
  }

  tbody img{
    width: 70px;
    height: 70px;
  }

  .checkout-header th, .checkout-item-row td, .checkout-item-description a{
    font-size:14px;
  }

 .checkout-item-row {
  gap:5px;
 }

  .checkout-item-row td{
    margin-bottom: 5px;
    gap:5px
  }

  .checkout-item-row select{
    padding: 0;
  }

  .checkout-item-row td:last-child {
    justify-content: flex-start;
    margin-bottom: 24px;
  }

  .cart-item-delete {
    width: 15px;
    height: 15px;
    margin-left: 7px;
    margin-top: 16px;
  }

  .total-info-wrapper {
    align-items: flex-start;
    padding: 0;
    margin-top: 10px;
    gap:5px;
  }

  /*Mobile Order Confirmation Page*/
  .order-confirmation-page-wrapper {
    padding-bottom: 15px;
  }
  .order-confirmation-header {
    margin-bottom: 10px;
  }

  .order-confirmation-wrapper {
    gap:25px;
    margin-bottom: 10px;
    padding-bottom: 10px;
  }

  .order-confirmation-wrapper h3 {
    margin-bottom: 10px;
    padding-bottom: 10px;
  }

  .confirmation-row {
    margin-bottom: 5px;
  }

  .confirmation-row h4{
    font-size: 15px;
  }

  /*Mobile Order History*/
  .order-history-page-wrapper {
    padding-bottom: 15px;
    gap:10px;
    margin-top: 20px;
  }

  .order-history-card {
    gap:40px
  }

  /*Mobile Login / Registration*/
  .login-form,
  .register-form {
    margin-top: 75px;
  }

  /*Mobile Shipping-Billing*/
  .shipping-billing-wrapper {
    flex-direction: column;
  }

  .payment-form-wrapper, .order-summary-wrapper {
    max-width: 100%;
  }

  .shipping-summary-wrapper {
    padding: 10px;
  }

  .order-summary-wrapper {
    margin-top: 10px;
  }

  /*Mobile Order Confirmation*/
  .order-confirmation-wrapper {
    display: flex;
    flex-direction: column;
  } 

  /*Mobile Order History*/
  .order-history-card-wrapper {
    max-width: 100%;
  }

  .order-history-card{
    display: flex;
    flex-direction: column;
  }

  .order-history-details-wrapper::before {
    transform: rotate(90deg);
    right:0;
    top: -50%;
    left:50%;
    height: 350%;
  }
}








